<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
        <v-row>
          
          
          <v-col cols="12" sm="12" md="3">
            <!-- <v-autocomplete
              v-model="id_customer"
              :items="customers"
              item-text="nombre"
              item-value="codigo"
              label="Escoge un Cliente"
              @change="getByCustomer"
              clearable
              rounded
              solo
              hide-details
            ></v-autocomplete> -->

            <v-text-field
              v-model="total_sum"
              label="Total a sumar"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="7">
          <v-row>

          <v-col cols="12" sm="12" md="3">
            <v-btn
              color="success"
              class="ma-2 white--text"
              @click="showModal"
              block
            >
              Sumar <v-icon right >
                fas fa-calculator
              </v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" sm="12" md="3">
            <v-btn
              color="primary"
              class="ma-2 white--text"
              @click="showModal2"
              block
            >
              Nueva
            </v-btn>
          </v-col>
          </v-row>

          </v-col>
         

        </v-row>
    </v-container>

      <!-- :items="currentItems" -->
    <v-data-table
      v-model="editedItem.selected"
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="id"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
      show-select
      checkbox-color="success"
      item-key="id"
      
    >
      <template v-slot:[`item.nombre`]="{ item }">
          <div v-if="item.nombre">
            {{ item.nombre }}
          </div v-else>
          <div>
            {{ item.nombre2 }}
          </div>
      </template>

      <template v-slot:top>
          <v-dialog v-model="dialog" max-width="1000px">
            <!-- <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="headline">Generar Constancia</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" autocomplete="off">
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-autocomplete
                          v-model="editedItem.id_productor"
                          :items="customers"
                          item-text="nombre"
                          item-value="codigo"
                          label="Escoge una Exportadora"
                          :rules="editedItem.id_productorRules"
                          required
                          clearable
                          rounded
                          solo
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                            v-model="editedItem.comment"
                            label="Observación"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-data-table
                    v-model="editedItem.selected"
                    :headers="headers_modal"
                    :items="editedItem.selected"
                    sort-by="id"
                    sort-desc
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Consultando datos... Espere por favor"
                    item-key="id"
                  >

                  <template slot="body.append">

                    <tr class="gray--text">
                      <!-- <th class="title"></th> -->
                      
                      <th colspan="8" class="totales">
                      <v-row class="pt-2">

                        <v-col cols="12" sm="12" md="4">
                          Total Oro: {{ numberWithCommas((parseFloat(sumField('quantity'))).toFixed(2)) }}
                        </v-col>
                      </v-row>
                      </th>
                      
                    </tr>
                  </template>
                  </v-data-table>

                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-row align="center" class="d-flex mb-2 mx-4">
                  <v-col cols="12" sm="12" md="6">

                  </v-col>
                  <v-col cols="12" sm="12" md="3">

                    <v-btn
                      color="warning"
                      rounded
                      outlined
                      class="flex-grow-1"
                      block
                      large
                      @click="dialog = false"
                      :elevation="0"
                      :minHeight="56"
                      >Cancelar
                    </v-btn>

                  </v-col>

                  <v-col  cols="12" sm="12" md="3">

                    <v-btn
                      color="primary"
                      rounded
                      block
                      large
                      @click="save"
                      :elevation="0"
                      :minHeight="56"
                      >Guardar
                    </v-btn>

                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
      </template>

      <template slot="body.append">

      <tr class="gray--text">
        <!-- <th class="title"></th> -->
        
        <th colspan="9" class="totales">
        <v-row class="pt-2">

          <v-col cols="12" sm="12" md="4">
            Total Oro: {{ numberWithCommas((parseFloat(sumField('quantity'))).toFixed(2)) }}
          </v-col>
        </v-row>
        </th>
        
      </tr>
      </template>

    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import jsPDF from 'jspdf';
import n2wordsES from 'n2words/lib/i18n/ES.mjs'

export default {
  name: "constance",
  data: () => ({
    dialog: false,
    dialogFactor: false,
    samplings: [],
    currentItems: [],
    sumItems: [],
    items: [],
    customers: [],
    types: [],
    damages: [],
    processes: [],
    damages: [],
    process: "",
    type: "",
    total_sum: "",
    id_customer: null,
    dates: [],
    modal: false,
    loader: null,
    loading3: false,
    dialogDelete: false,
    search: "",
    loading: false,
    titulo: "Constancia de Retenciones",
    headers: [
      { text: "Fecha", align: "left", value: "date_created"},
      { text: "Nombre", align: "left", value: "nombre" },
      { text: "Número de Comprobante", value: "receipt" },
      // { text: "Nota de Peso", value: "coddoc" },
      // { text: "Clase de Café", value: "product" },
      // { text: "Cantidad", value: "cant" },
      // { text: "Precio", value: "precio" },
      { text: "Retención", value: "quantity" }
    ],
    headers_modal: [
      { text: "Nombre", align: "left", value: "nomprov" },
      { text: "#Recibo", value: "receipt" },
      { text: "Cantidad", value: "quantity" },
    ],
    editedIndex: -1,

    valid: true,
    

    editedItem: {
      id_productor: null,
      comment: "",
      selected: [],
      id_productorRules: [v => !!v || "Escoge un Productor"],
    },
    defaultItem: {
      d_productor: null,
      comment: "",
      selected: [],
      id_productorRules: [v => !!v || "Escoge un Productor"],
    },
  }),

  computed: {
    
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
    dateRangeText: {
        get()
        {
            return this.dates.join(' ~ ')
        },
        set()
        {
            this.dates = []
        }
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close();
    // },
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getSamplings(){
      await axios.get(this.db + 'constance_retentions',
        {
          headers: this.headers_db
        }).then(response => {
          let datos2 = response.data.data;
          // datos2.forEach(element => {
          //   element.total_ret = this.calc_ret(element.cant, element.id_coffee)
          // });
          this.samplings = this.currentItems = datos2;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
        });
    },

    async getCustomers(){
      await axios.get(this.db + 'providers',
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
        });
    },

    async getTypes(){
      await axios.get(this.db + 'types',
        {
          headers: this.headers_db
        }).then(response => {
          this.types = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getTypes(); })
        });
    },
    // FIN Metodos base de datos

    // Metodos Generales
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    
    sumField(key) {
      console.log("key",key);
      // sum data in give key (property)
      return this.editedItem.selected.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    // calc_ret(cant, type) {

    //   let element = this.types.filter(doc => {
    //     return doc.codigo == type;
    //   });

    //   if (!element.length > 0) {
    //     return;
    //   }
    //   let factor  = (element[0].factor / 100);
    //   return (parseFloat(cant) * parseFloat(factor)).toFixed(2)
    // },




    showModal2() {
      this.dialog = true;
    },

    showModal() {
      let candidates = [];
      this.currentItems.forEach(item => {
        candidates.push({
          id: parseInt(item.id), 
          nomprov: item.nomprov,
          receipt: item.receipt,
          quantity: parseFloat(item.quantity)
        });
      });

      let sumItems = this.combinationSum(candidates, parseFloat(this.total_sum))[0];
      // console.log(sumItems == undefined);
      if (sumItems != undefined) {
        this.editedItem.selected = sumItems;
      }
    },

    combinationSumRecursive(
      candidates,
      remainingSum,
      finalCombinations = [],
      currentCombination = [],
      startFrom = 0
    ) {

    if (finalCombinations.length > 0) {
      return;
    }

    if (parseFloat(remainingSum) < 0) {
      console.log("sum < 0", finalCombinations);
      // By adding another candidate we've gone below zero.
      // This would mean that the last candidate was not acceptable.
      // console.log(currentCombination);
      return finalCombinations;
    }

    if (parseFloat(remainingSum) === 0) {

      console.warn("sum = 0", finalCombinations);
      // const set = new Set(currentCombination);
      // if(set.size === currentCombination.length) {
        // console.log(currentCombination);
        finalCombinations.push(currentCombination.slice());
      // }
      // If after adding the previous candidate our remaining sum
      // became zero - we need to save the current combination since it is one
      // of the answers we're looking for.
      

      // finalCombinations = currentCombination;
      return finalCombinations;
    }

    // If we haven't reached zero yet let's continue to add all
    // possible candidates that are left.
    for (
      let candidateIndex = startFrom;
      candidateIndex < candidates.length;
      candidateIndex += 1
    ) {
      const currentCandidate = candidates[candidateIndex];
      // Let's try to add another candidate.
      // console.log('currentCandidate', currentCandidate)

      const personaBuscada = currentCombination.find(item => parseInt(item.id) === parseInt(currentCandidate.id));
      // console.log(personaBuscada);
      if (!personaBuscada) {
        currentCombination.push(currentCandidate);
        // Explore further option with current candidate being added.
        this.combinationSumRecursive(
          candidates,
          parseFloat(remainingSum - currentCandidate.quantity).toFixed(2),
          finalCombinations,
          currentCombination,
          candidateIndex
        );
  
        // BACKTRACKING.
        // Let's get back, exclude current candidate and try another ones later.
        currentCombination.pop();
      }
    }

    return finalCombinations;
  },

  combinationSum(candidates, target) {
    console.log(target);
    return this.combinationSumRecursive(candidates, target);
  },

    getByCustomer() {
      console.log(this.id_customer, this.samplings);
      if (this.id_customer) {
        this.currentItems = this.samplings.filter(doc => {
          return doc.codprov == this.id_customer;
        });
      }
      else {
        this.currentItems = this.samplings;
      }
    },
    
    getSettings() {
      // this.loader = 'loading3'
      this.getTypes();
      this.dialogFactor = true;
    },

    close() {
      this.dialog = false;
      this.dialogFactor = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      }, 300);
    },
    save() {
      // this.generatePdf(this.editedItem);
      
      if (this.$refs.form.validate()) {
        let selected = this.editedItem.selected;
        // selected.forEach(element => {
        //   element.quantity = this.calc_ret(element.cant, element.id_coffee);
        // });

        axios.post(this.db + 'retentions/add', {
          receipt: this.editedItem.comment,
          id_provider: this.editedItem.id_productor,
          ids_cxp: selected
        },
        {
          headers: this.headers_db
        })
        .then((response) => {
          console.log(response.data.message);
          this.$store.dispatch("setSnackbar", {
            text: response.data.message,
          });
          // this.getSamplings();
          selected.forEach(element => {
            this.samplings = this.samplings.filter(doc => {
              return doc.id_cxp != element.id_cxp
            })
          });
          this.currentItems = this.samplings;
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            text: error,
          });
        });
      }
    },

    saveFactor(payload) {
      console.log(payload.factor);
      if (!parseFloat(payload.factor) > 0) {
        return;
      }
      axios.post(this.db + 'factor/update', {
        codigo: payload.codigo,
        factor: payload.factor,
      },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        //this.getSamplings();
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch("setSnackbar", {
          text: error,
        });
      });

    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

  async getAll() {
      this.loader = 'loading3'
      this.loading = true;
      await this.getSamplings();
      this.getCustomers();
    },
  },
  async mounted() {
    console.log(n2wordsES(8118.00));
    this.loading = true;
    await this.getTypes();
    await this.getSamplings();
    this.getCustomers();
  },
};
</script>

<style lang="scss">
  .totales {
    font-size: medium !important;
  }
</style>
